.section-title {
  background-color: $green-100;
  padding: 18px 0 0;
  h1 {
    margin: 10px 0 20px;
    color: $green-300;
    line-height: 80%;
    @include mq($until: mobile) {
      font-size: 35px;
    }
  }
  //span {
  //	margin-right: 15px;
  //}
}

.section-menu {
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 200;
  .icon, .title {
    display: block;
    text-align: center;
    font-weight: 600;
    line-height: 1.32;
  }
  a {
    background: transparent;
    color: #fff;
    display: block;
    padding: 8px 0;
    &:hover {
      background: transparentize(#000, 0.9);
      text-decoration: none;
    }
    @include transition(background-color 0.2s ease-out);
  }
  li {
    float: left;
    width: 25%;
    list-style: none;
    padding: 0;
    margin: 0;
    &.current a {
      background: #fff;
      color: $green-500;
    }
  }
}

//== Sections

@each $section-scheme in $section-schemes {
  $section-name: nth($section-scheme, 1);
  $section-color: nth($section-scheme, 2);
  $section-color-darken: darken($section-color, 20%);
  $section-color-lighten: lighten($section-color, 25%);

  .bg-#{$section-name} {
    background-color: $section-color;
    @if lightness($section-color) < 50% {
      color: lighten($section-color, 30%)!important;
    } @else {
      color: darken($section-color, 30%) !important;
    }
  }
  .bg-#{$section-name}-inverse {
    background-color: transparentize($section-color,0.82);
    @if lightness($section-color) < 50% {
      color: $section-color;
    } @else {
      color: darken($section-color, 30%);
    }
  }

  .section-#{$section-name} {

    /* page scheme */
    h2 {
      color: $section-color-darken;
    }
    h3 {
      color: $section-color;
    }
    hr {
      border-color: transparentize($section-color, 0.82);
    }

    .top-nav {
      background-color: $section-color;
    }
    .main-nav {
      background-color: transparentize($section-color-darken, 0.25);
      li.active a {
        color: $section-color;
      }
    }
    footer {
      // background-color: $section-color-darken;
      a {
        color: $section-color-lighten;
      }
      a:hover {
        color: $section-color;
      }
    }
    .breadcrumb {
      // background-color: $section-color-darken;
      a {
        color: darken($section-color, 10%);
      }
      a:hover {
        color: darken($section-color, 30%);
      }
    }
    
    .footer-bottom {
      background-color: darken($section-color-darken, 10%);
    }
    .event-description h4, .event-description a {
      color: $section-color-darken;
    }
    .event-description a:hover {
      color: $section-color;
    }
    .header-img {
      background-color: $section-color-lighten;
    }
    /*
    #usersidebar{
        background-color: $section-color-darken;
    }
    */
    .section-title {
      background-color: $section-color;
      h1 {
        color: $section-color-darken;
      }
    }
    .section-menu {
      li.current a {
        color: $section-color-darken;
      }
    }

    .user-profile-content-details {
      background: $section-color-darken;
      color: $section-color-lighten;
    }
    .btn-primary {
      background-color: $section-color;
      border-color: $section-color-darken;
      &:active {
        background-color: $section-color-darken;
        border-color: $section-color-darken;
      }
    }
  }

  .section-box-#{$section-name} {
    display: block;
    overflow: hidden;
    text-align: center;
    margin: 15px 0;
    position: relative;
    // @include box-shadow($shadow-medium);
    @include box-shadow(0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23));
    border-radius: $border-radius-small;
    @include transition(all 0.2s ease-in-out);
    .section-img {
      img {
        width: 100%;
      }
    }
    &:hover {
      @include box-shadow(0 0 30px rgba(0, 0, 0, 0.20), 0 0 15px rgba(0, 0, 0, 0.30));
    }
    .section-info {
      position: absolute;
      padding: 10px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($section-color, 0.75);
      @include transition(all 0.2s ease-in-out);
      align-items: center;
      display: flex;
      justify-content: center;
      &:hover {
        text-decoration: none;
        background: rgba($section-color, 0);
        // background-color: lighten($section-color,8%);
      }
    }
    h3 {
      display: block;
      font-size: $font-size-base;
      font-weight: normal;
      font-family: $headings-font-family;
      letter-spacing: 1px;
      font-size: 16px;
      line-height: 1;
      margin: 0;
      color: #fff;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.45);
      // @if lightness($section-color) < 50% {
      // 	color: lighten($section-color,30%);
      // }
      // @else{
      // 	color: darken($section-color,30%);
      // }
      @include mq(desktop) { font-size: 20px; }
      @include mq(wide) { font-size: 22px; }
    }
  }
  .box-#{$section-name} {
    border-top-color: $section-color !important;
    h2, h3, h4, h5, .h4 {
      color: $section-color !important;
    }
    .btn-primary {
      background-color: $section-color;
      border-color: darken($section-color, 5%);
    }
  }

}

.sections {
  // background: url(img/seccions-bg.jpg);
  // background-size: cover;
  @include clearfix();
}