/*
 * Component: Box
 * --------------
 */
.box {
  position: relative;
  border-radius: $box-border-radius;
  background: #ffffff;
  border-top: 3px solid $gray;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: $box-boxshadow;

  // Box color variations
  &.box-primary {
    border-top-color: $brand-primary;
  }
  &.box-info {
    border-top-color: $brand-info;
  }
  &.box-danger {
    border-top-color: $brand-danger;
  }
  &.box-warning {
    border-top-color: $brand-warning;
  }
  &.box-success {
    border-top-color: $brand-success;
  }
  &.box-default {
    border-top-color: $gray;
  }

  // collapsed mode
  &.collapsed-box {
    .box-body,
    .box-footer {
      display: none;
    }
  }

  .nav-stacked {
    > li {
      border-bottom: 1px solid $box-border-color;
      margin: 0;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  // fixed height to 300px
  &.height-control {
    .box-body {
      max-height: 300px;
      overflow: auto;
    }
  }

  .border-right {
    border-right: 1px solid $box-border-color;
  }
  .border-left {
    border-left: 1px solid $box-border-color;
  }

  //SOLID BOX
  //---------
  //use this class to get a colored header and borders

  &.box-solid {
    border-top: 0px;
    > .box-header {
      .btn.btn-default {
        background: transparent;
      }
      .btn,
      a {
        &:hover {
          background: rgba(0,0,0,0.1)!important;
        }
      }
    }


    // Box color variations
    &.box-default {
      @include box-solid-variant($gray, #444);
    }
    &.box-primary {
      @include box-solid-variant($brand-primary);
    }
    &.box-info {
      @include box-solid-variant($brand-info);
    }
    &.box-danger {
      @include box-solid-variant($brand-danger);
    }
    &.box-warning {
      @include box-solid-variant($brand-warning);
    }
    &.box-success {
      @include box-solid-variant($brand-success);
    }


    > .box-header > .box-tools .btn {
      border: 0;
      box-shadow: none;
    }

    // Fix font color for tiles
    &[class*='bg'] {
      > .box-header {
        color: #fff;
      }
    }

  }

  //BOX GROUP
  .box-group {
    > .box {
      margin-bottom: 5px;
    }
  }


  // jQuery Knob in a box
  .knob-label {
    text-align: center;
    color: #333;
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 0.3em;
  }
}

.box,
.overlay-wrapper {
  // Box overlay for LOADING STATE effect
  > .overlay,
  > .loading-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    z-index: 50;
    background: rgba(255, 255, 255, 0.7);
    border-radius: $box-border-radius;
    > .fa {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      color: #000;
      font-size: 30px;
    }
  }

  .overlay.dark {
    background: rgba(0, 0, 0, 0.5);
  }
}

//Add clearfix to header, body and footer
.box-header,
.box-body,
.box-footer {
  @include clearfix;
}

//Box header
.box-header {
  color: #444;
  display: block;
  padding: $box-padding;
  position: relative;

  //Add bottom border
  &.with-border {
    border-bottom: 1px solid $box-border-color;
    .collapsed-box & {
      border-bottom: none;
    }
  }

  //Icons and box title
  > .fa,
  > .glyphicon,
  //> .ion,
  .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
  }
  > .fa,
  > .glyphicon
  //> .ion
  {
    margin-right: 5px;
  }
  > .box-tools {
    position: absolute;
    right: 10px;
    top: 5px;
    [data-toggle="tooltip"] {
      position: relative;
    }
    //float: none!important;
    &.pull-right {
      .dropdown-menu {
        right: 0;
        left: auto;
      }
    }
  }
}

//Box Tools Buttons
.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  box-shadow: none!important;
  color: darken($box-default-border-top-color, 20%);
  .open &,
  &:hover {
    color: darken($box-default-border-top-color, 40%);
  }
  &:active {
    outline: none!important;
  }
}

//Box Body
.box-body {
  @include border-bottom-radius($box-border-radius);
  padding: $box-padding;
  .no-header & {
    @include border-top-radius($box-border-radius);
  }
  // Tables within the box body
  > .table {
    margin-bottom: 0;
  }

  // Calendar within the box body
  .fc {
    margin-top: 5px;
  }

  .full-width-chart {
    margin: -19px;
  }
  &.no-padding .full-width-chart {
    margin: -9px;
  }

  .box-pane {
    border-bottom-left-radius: $box-border-radius;
  }
  .box-pane-right {
    border-bottom-right-radius: $box-border-radius;
  }
}

//Box footer
.box-footer {
  @include border-bottom-radius($box-border-radius);
  border-top: 1px solid $box-border-color;
  padding: $box-padding;
  background-color: $box-footer-bg;
}
.chart-legend {
  @extend .list-unstyled;
  margin: 10px 0;
  > li {
    @media (max-width: $screen-sm-max) {
      float: left;
      margin-right: 10px;
    }
  }
}

//Widgets
//-----------

/* Widget: TODO LIST */

.todo-list {
  margin: 0;
  padding: 0px 0px;
  list-style: none;
  overflow: auto;
  // Todo list element
  > li {
    border-radius: 2px;
    padding: 10px;
    background: #f4f4f4;
    margin-bottom: 2px;
    border-left: 2px solid #e6e7e8;
    color: #444;
    &:last-of-type {
      margin-bottom: 0;
    }
    // Color varaity
    &.danger {
      border-left-color: $brand-danger;
    }
    &.warning {
      border-left-color: $brand-warning;
    }
    &.info {
      border-left-color: $brand-info;
    }
    &.success {
      border-left-color: $brand-success;
    }
    &.primary {
      border-left-color: $brand-primary;
    }

    > input[type='checkbox']  {
      margin: 0 10px 0 5px;
    }

    .text {
      display: inline-block;
      margin-left: 5px;
      font-weight: 600;
    }

    // Time labels
    .label {
      margin-left: 10px;
      font-size: 9px;
    }

    // Tools and options box
    .tools {
      display: none;
      float: right;
      color: $brand-danger;
      // icons
      > .fa,
      //> .ion,
      > .glyphicon
      {
        margin-right: 5px;
        cursor: pointer;
      }

    }
    &:hover .tools {
      display: inline-block;
    }

    &.done {
      color: #999;
      .text {
        text-decoration: line-through;
        font-weight: 500;
      }

      .label {
        background: $gray!important;
      }
    }
  }

  .handle {
    display: inline-block;
    cursor: move;
    margin: 0 5px;
  }

}
// END TODO WIDGET

/* Chat widget (DEPRECATED - this will be removed in the next major release. Use Direct Chat instead)*/
.chat {
  padding: 5px 20px 5px 10px;

  .item {
    @include clearfix;
    margin-bottom: 10px;
    // The image
    > img {
      //display: inline-block;
      width: 40px;
      height: 40px;
      border: 2px solid transparent;
      border-radius: 50% !important;
      &.online {
        border: 2px solid $brand-success;
      }
      &.offline {
        border: 2px solid $brand-danger;
      }
    }

    // The message body
    > .message {
      margin-left: 55px;
      margin-top: -40px;
      > .name {
        display: block;
        font-weight: 600;
      }
    }

    // The attachment
    > .attachment {
      border-radius: $border-radius-small;
      background: #f4f4f4;
      margin-left: 65px;
      margin-right: 15px;
      padding: 10px;
      > h4 {
        margin: 0 0 5px 0;
        font-weight: 600;
        font-size: 14px;
      }
      > p, > .filename {
        font-weight: 600;
        font-size: 13px;
        font-style: italic;
        margin: 0;

      }
      @include clearfix;
    }
  }

}
//END CHAT WIDGET

//Input in box
.box-input {
  max-width: 200px;
}

//A fix for panels body text color when placed within
// a modal
.modal {
  .panel-body {
    color: #444;
  }
}


/*
 * Component: Small Box
 * --------------------
 */

.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: $box-boxshadow;
  // content wrapper
  > .inner {
    padding: 10px;
  }

  > .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0,0,0,0.1);
    text-decoration: none;
    &:hover {
      color: #fff;
      background: rgba(0,0,0,0.15);
    }
  }

  h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;

  }

  p {
    font-size: 15px;
    > small {
      display: block;
      color: #f9f9f9;
      font-size: 13px;
      margin-top: 5px;
    }
  }

  h3, p {
    z-index: 5px;
  }

  // the icon
  .icon {
    @include transition(all $transition-speed linear);
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0, 0, 0, 0.15);
  }

  // Small box hover state
  &:hover {
    text-decoration: none;
    color: #f9f9f9;
    // Animate icons on small box hover
    .icon {
      font-size: 95px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  // No need for icons on very small devices
  .small-box {
    text-align: center;
    .icon {
      display: none;
    }
    p {
      font-size: 12px;
    }
  }
}

.masonry {
    .publication-card{
      position: static;
    }
	.box{
        position: static;
		box-shadow: 0 10px 20px rgba(black, 0.2);
        padding: 10px;
		.h3 {
			text-align: center;
		}
	}
	@include mq(tablet){
		-webkit-column-gap: 40px;
		   -moz-column-gap: 40px;
				column-gap: 40px;        
		-webkit-column-count: 2;
		   -moz-column-count: 2;
				column-count: 2;
		.box {
			display: inline-block;
			padding: 20px;
			margin: 0 0 40px;
			width: 100%;
		}
	}
}

.box-licencia {
	box-shadow: 0 10px 20px rgba(black, 0.2);
	padding: 20px;
	@include mq(tablet) {
		padding: 40px;
	}
}