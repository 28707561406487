.wall-timeline {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}
.wall-timeline:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: 0;
  margin-left: -1px;
  width: 1px;
  background-color: $gray-light;
  opacity: 0.4;
}
.timeline-date {
  position: relative;
  z-index: 1;
  width: 300px;
  margin: 10px auto 10px;
  background: #fff;
  padding: 10px;
  text-align: center;
  clear: both;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff !important;
}
.timeline-item {
  position: relative;
  z-index: 2;
  width: 50%;
  margin-bottom: 20px;
  .loading{
    display: none;
    z-index: 999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: #000;
    background: rgba(255,255,255,0.7);
    padding-top: 40%;
  }
  &.waiting{
    .loading{
      display: block;
    }
  }
}
.timeline-item-date {
  position: relative;
  display: inline-block;
  height: 40px;
  padding: 10px 8px 10px 12px;
  margin-bottom: 18px;
  line-height: 20px;
  background: #eee;
  clear: both;
}
.timeline-item-date span {
  display: inline-block;
  vertical-align: top;
}
.timeline-item-date span:first-child {
  font-size: 20px;
  padding: 0 10px 0 0;
}
.timeline-item-date span:last-child {
  font-size: 11px;
  color: #bbb;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.timeline-item-date:after,
.timeline-item-date:before {
  display: block;
  content: '';
  position: absolute;
}
.timeline-item-date:after {
  top: 0;
}
.timeline-item-date:before {
  top: 14px;
  border-radius: 50%;
  background: #fff;
}
.timeline-item-left {
  float: left;
  clear: both;
  padding-right: 30px;
  margin-top: 20px;
}
.timeline-item-left > div {
  clear: right;
}
.timeline-item-left .timeline-item-date {
  float: right;
  border-radius: 3px 0 0 3px;
}
.timeline-item-left .timeline-item-date:after {
  right: -12px;
  border-top: 20px solid transparent;
  border-left: 12px solid #eee;
  border-bottom: 20px solid transparent;
  border-right: 0 solid transparent;
}
.timeline-item-left .timeline-item-date:before {
  right: -35px;
  width: 11px;
  height: 11px;
}
.timeline-item-right {
  float: right;
  padding-left: 30px;
  margin-top: 80px;
  clear: right;
}
.timeline-item-right .timeline-item-date {
  padding: 10px 12px 10px 8px;
  border-radius: 0 3px 3px 0;
}
.timeline-item-right .timeline-item-date:after {
  left: -12px;
  border-top: 20px solid transparent;
  border-left: 0 solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 12px solid #eee;
}
.timeline-item-right .timeline-item-date:before {
  left: -36px;
  width: 11px;
  height: 11px;
}
@media (max-width: 767px) {
  .timeline-item {
    width: auto;
    padding: 10px 10px;
    margin: 72px -10px 110px;
    background: #fff;
    border-radius: 3px;
    float: none;
  }
  .timeline-item .timeline-item-date {
    position: absolute;
    top: -41px;
    left: 50%;
    display: block;
    width: 90px;
    margin-left: -45px;
    padding: 0px 8px 5px 12px;
    float: none;
    text-align: center;
    border-radius: 3px 3px 0 0;
  }
  .timeline-item .timeline-item-date:after {
    width: 0;
    top: -10px;
    left: 0;
    border-top: 0 solid transparent;
    border-left: 45px solid transparent;
    border-bottom: 10px solid #eee;
    border-right: 45px solid transparent;
  }
  .timeline-item .timeline-item-date:before {
    top: -32px;
    left: 50%;
    margin: 0 0 0 -6px;
    width: 12px;
    height: 12px;
  }
}
.timeline:after {
  background: #DEE0E0;
}
.timeline-item-date span:first-child {
  color: #21252b;
}
.timeline-date {
  color: #21252b;
}
.timeline-item-left .timeline-item-date:before {
  border: 1px solid #eee;
}
.timeline-item-right .timeline-item-date:before {
  border: 1px solid #eee;
}
@media (max-width: 767px) {
  .timeline-item {
    border: 1px solid #eee;
  }
  .timeline-item .timeline-item-date:before {
    border: 1px solid #eee;
  }
}


.timeline-item-text{

}

.timeline-item-details{
}

.timeline-item-info{
	font-weight: 200;
	font-size: 14px;
	color: $gray;
	margin-bottom: 4px;
	span{
		font-weight: 600;
		color: $green-200;
		font-size: $font-size-base;
	}
}
.timeline-item-avatar{
	margin: 0 10px 10px 0px;
	float: left;
	img{
		border-radius: $border-radius-small;
	}
}

/* Comentarios */

.timeline-item-comments{
	font-size: 90%;
	margin-left: 20px;
}
.timeline-item-comment-text{

}
.timeline-item-comment-image{
	margin-bottom: 10px;
}

.timeline-item-comment-details{
	@include clearfix();
}

.timeline-item-comment-info{
	font-weight: 200;
	color: $gray;
	margin-bottom: 4px;
	span{
		font-weight: 600;
		color: $lime-200;
	}
}
.timeline-item-comment-avatar{
	margin: 0 5px 5px 0px;
	float: left;
	img{
		border-radius: $border-radius-small;
	}
}

/* Acciones */
.timeline-item-actions{
	margin-top: 10px;
	// border: 1px $gray-lighter solid;
	border-radius: $border-radius-small;
	padding: 5px 5px 0;
}

.users-pills {
    padding: 0;
    margin: 0;
	li {
		background: $gray-lighter;
		display: inline-block;
		height: 30px;
        font-size: 80%;
		line-height: 30px;
		margin: 0 10px 10px 0;
		padding: 0 15px 0 0;
		@include border-left-radius(15px);
		@include border-right-radius(15px);
		overflow: hidden;
      @include transition(all .3s ease-in-out);
      &:hover{
        background-color: $lime-100;
      }
	}
	img {
		width: 30px;
		height: 30px;
		margin: 0 10px 0 0;
		border-radius: 50%;
		vertical-align: top;
	}
	a {
		display: block;
        color: $text-color;
		&:hover {
			text-decoration: none;
            color: $lime-500;
		}
	}
}

.comment-form {
	.btn {
		height: 34px;
	}
}

.is_owner{
  background: lighten($lime-100,44);
  padding-top: 5px;
  padding-bottom: 5px;
  &.timeline-item-left{
    padding-left: 5px;
    margin-left: -1px;
  }
  &.timeline-item-right{
    padding-right: 5px;
    margin-right: 0px;
  }
}

.publication-tools{
  z-index: 3;
  position: absolute;
  opacity: 0;
  @include transition(opacity 0.3s ease-in-out);
}
.timeline-item:hover .publication-tools{
  opacity: 1;
}
.timeline-item-left .publication-tools{
  left: 5px;
}
.timeline-item-right .publication-tools{
  right: 5px;
}

.comment-tools{
  z-index: 30;
  position: absolute;
  opacity: 0;
  @include transition(opacity 0.3s ease-in-out);
  right: 5px;
}

.timeline-item-comment:hover .comment-tools{
  opacity: 1;
}

.publication-unique{
  .timeline-item{
    width: 100%;
    padding: 20px;
    border-radius: $border-radius-medium;
  }
  .publication-tools{
    right: 20px;
  }
}

/* PUBLICATION CARDS */
.publication-card{
  display: block;
  text-decoration: none;
  color: $text-color;
  overflow: hidden;
  background: #fff;
  border: 1px $gray-light solid;
  margin-bottom: $line-height-computed;
  @include box-shadow($shadow-medium);
  font-size: 85%;
  &:hover,&:active,&:focus{
    text-decoration: none;
    color: $text-color;
    border-color: $green-100;
  }
  .card-header{
    line-height: 100%;
    .thumb{
      float: left;
      max-height: 60px;
      max-width: 60px;
      margin-right: 8px;
      overflow: hidden;
      border-bottom-right-radius: $border-radius-medium;
    }
    .text{
      padding: 10px;
      background: $green-300;
      color: #fff;
    }
    &:after{
      @extend .clearfix;
    }
  }
  .card-body{
    padding: 12px;
  }
  .card-footer{
    background: transparentize(#000,0.92);
    padding: 4px 12px;
    font-size: 85%;
    .date{
      float: left;
    }
    .link{
      float: right;
    }
    &:after{
      @extend .clearfix;
    }
  }
}