body {
	font-weight: 200;
}

section.main-header{
  z-index: 3;
  @include box-shadow($shadow-medium);
  padding: 10px 0;
  background-image: linear-gradient(#fff, $gray-lighter);
}
section.main{
	background: #fff;
	padding: 0px 0 80px 0;
}

h1, .h1{
	font-family: $headings-font-family;
	font-weight: bold;
	font-size: 45px;
	line-height: 1;
	@include mq(tablet) {
		font-size: 60px;
	}
}

h2, .h2{
  	font-family: $headings-font-family;
	font-weight: bold;
	font-size: 38px;
	line-height: 42px;
	color: $green-200;
}

h3, .h3{
	font-weight: bold;
	font-size: 28px;
	line-height: 32px;
}

h4, .h4{
	font-weight: 200;
	font-size: 20px;
	line-height: 22px;
	color: $green-500;
	text-transform: uppercase;
}

a {
	outline: none;
	// overflow-wrap: break-word;
	// word-wrap: break-word;
	// -ms-word-break: break-all;
	// 	word-break: break-all; 	// This is the dangerous one in WebKit, as it breaks things wherever
	// 	word-break: break-word; //Instead use this non-standard one:
	// -webkit-hyphens: auto; 		// Adds a hyphen where the word breaks, if supported (No Blink)
	//    -moz-hyphens: auto;
	// 	-ms-hyphens: auto;
	// 		hyphens: auto;
}

img {
//	max-width: 100%;
//	height: auto;
}

#advantages-carousel img {
		max-width: 100%;
		height: auto;
}

iframe {
	max-width: 100%;
}

textarea {
	max-width: 100%;
	max-height: 800px;
}

.breadcrumb{
  	margin-bottom: 0;
	font-size: 15px;
	font-weight: 200;
	&>li+li:before{
		@extend .ion;
		content: $ionicon-var-ios-arrow-right;
	}
}

.activity-labels {
	display: inline-block;
	float: left;
	margin: 0 5px 5px 0;
	.label {
		font-size: 16px;
		display: inline-block;
		padding: 5px 10px;
		font-weight: normal;
		line-height: 1.3;
	}
}

.page-header {
  text-align: center;
  border-bottom: 0;
  position: relative;
  z-index: 1;
  &:before {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 5px;
	top: 30px;
	border-bottom: 1px solid $gray-lighter;
	border-top: 1px solid $gray-lighter;
	z-index: -1;
  }
  h1{
	margin: 0;
	display: inline-block;
	background-color: #fff;
	padding: 0 30px;
  }
}

/* SLIDER */

.single_slider{
  .item{
	position: relative;
  }
  .img{
	max-width: 100%;
	height: auto;
	z-index: 1;
  }
  .text{
	position: absolute;
	bottom: 24px;
	left: 0;
	background: rgba(0,0,0,0.8);
	width: 90%;
	padding: 0 20px;
  }
  .owl-pagination{
	text-align: center;
	margin: 8px 0 30px;
  }
}


/* MODALS */

.modal-success {
  .modal-header{
	h4{
	  color: #fff !important;
	}
	background: $brand-success;
	border-color: darken($brand-success, 10%);
  }
}
.modal-danger {
  .modal-header{
	h4{
	  color: #fff !important;
	}
	background: $brand-danger;
	border-color: darken($brand-danger, 10%);
  }
}


/* NOTIFICATIONS */
.notifications-list{
  background: #f4f4f4;
  border-radius: $border-radius-medium;
  @include clearfix();
  padding: 5px;

  .wrapper{
	float:left;
	padding: 5px;
	@include mq(mobile){
	  width: 100%;
	}
	@include mq(tablet){
	  width: 33%;
	}
	@include mq(desktop){
	  width: 25%;
	}
  }

  .notification-item{
	display: block;
	background-color: #fff;
	border: 1px $gray-lighter solid;
	border-radius: $border-radius-small;
	@include box-shadow($shadow-small);
	padding: 5px 10px;
	color: $green-400;
	&:hover{
	  color: $green-500;
	  text-decoration: none;
	}
	&.unvisited {
	  color: $green-100;
	  background-color: transparentize($green-100, 0.9);
	  border-color: transparentize($green-100, 0.7);
	}
	&.unvisited:hover{
	  background-color: transparentize($green-100, 0.76);
	  color: $green-300;
	}
	&.new{
	  border-top-width: 5px;
	}
  }
}

.timeline-item-text {
	@include mq($until: tablet) {
		font-size: 14px !important;
	}
}

@media (max-width: 767px) {
	.timeline-item .timeline-item-date {
		padding-top: 10px !important;
	}
}

$home-sections-gutter: 2px;

.home-sections-grid {
	margin-left: -$home-sections-gutter;
	margin-right: -$home-sections-gutter;
	.col-md-4, .col-sm-4, .col-xs-4, .col-sm-3, .col-xs-3, .col-sm-6, .col-xs-6 {
		padding-left: $home-sections-gutter;
    padding-right: $home-sections-gutter;
	}
	a {
		margin: $home-sections-gutter 0 !important;
	}
	@include mq($until: tablet) {
		.section-img {
			height: 100px;
		}
	}
}

.social-pics{
	p{
		font-size: 85%;
		text-align: justify;
		font-weight: bolder;
		color: #2e5c3a;
		padding: 10px 2px 2px 2px;
	}
	img{
		display:block; margin-left: auto; margin-right: auto; // center
		border-radius: 3px;
		box-shadow: 2px 2px 2px 1px #aaa;
	}
}

.home-callout{
	
}
