.post-item {
  margin: 40px 0 80px;
  h1 > a {
    color: $text-color;
  }
}

.post-content {
  img {
    max-width: 100%;
    display: block;
    margin: 20px auto;
    @include box-shadow($shadow-medium);

  }
  text-align: justify;
  margin: 20px 0 40px;
  @include clearfix();
  @include mq($until: tablet) {
    font-size: 14px;
  }
}

.post-info {
  border-top: 1px dotted $gray-light;
  padding-top: 5px;
}

.post-adjacents{
  margin-top: 60px;
  background: #eee;
  padding-top: 10px;
  a{
    padding: 15px;
    display: block;
    color: $text-color;
    @include transition(all 0.3s ease-in-out);
    &:hover{
      color: $brand-primary;
      background-color: $green-500;
      text-decoration: none;
    }
  }
  .prev-post{
    .icon{
      width: 30px;
      float: left;
    }
    .text{
      margin-left: 40px;
    }
  }
  .next-post{
    .icon{
      width: 30px;
      float: right;
    }
    .text{
      margin-right: 40px;
    }
  }
}

.blog-post {
  margin: 0;
  position: relative;
  .post-image {
    img {
      border-radius: 4px;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li, .post-date {
    font-size: 14px;
    color: grey;
  }
  .btn {
    position: absolute;
    right: 0;
    bottom: 10px;
    color: #fff;
    font-size: 12px;
  }
  hr {
    margin-top: 10px;
    border-color: rgba(black, 0.05);
  }
  .post-resume {
    font-size: 14px;
  }
}

.blog-sidebar{
  padding: 15px;
  background: transparentize(white,0.8);
  margin: 40px 0;
  @include box-shadow($shadow-medium);
  a{
    color: $green-500;
  }
  .title-pill{
    margin-top: 0;
  }
  ul{
    padding: 0 0 15px;
    margin-bottom: 0;
  }
  li {
    list-style: none;
    font-size: 85%;
    line-height: 1.3;
    padding: 0 10px;
    margin-bottom: $line-height-computed/3;
  }
}

.blog-comments{
  margin: 0 10px !important;
  .timeline-item-comment{
    padding-top: 10px;
    border-top: 1px $gray-lighter solid;
  }
}

.blog-post-box {
  h3 a {
    color: $green-500;
  }
  @include mq($until: tablet) {
    margin-bottom: 30px;
  }
}

.pagination>li>a, .pagination>li>span {
  margin-bottom: 3px;
}