footer{
	z-index: 2;
	padding: 50px 0 30px;
	background-color: #222;
	background: #222 url('img/footer-bg-2.jpg') no-repeat right bottom;
	background-attachment: fixed;
	color: #fff;
	h5{
		font-size: 24px;
	}
	a,a:hover{
		color: lighten($green-100,25%);
		font-weight: 100;
	}
	li{
	}
}

.footer-bottom{
	background-color: $green-400;
	font-size: 13px;
    font-weight: 100;
    margin-top: 30px;
    padding: 20px 0 10px;

    .list-inline li + li:before{
		content: "";
		margin-right: 10px;
		border-left: 1px transparentize(#fff,0.8) solid;
	}
}

.footer-bottom-left{

}

.footer-bottom-right{
	@include mq(desktop){
		text-align: right;
	}
}
