.events-widget {
  margin: 40px 0;
}

.event-item {
  .event-date {
    color: $green-100;
    .day {
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 32px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $green-100;
      color: #fff;
      text-align: center;
    }
    .date-text {
      font-size: 14px;
      display: inline-block;
      text-transform: uppercase;
      line-height: 32px;
    }
  }
  .event-description {
    border-left: 1px $green-100 solid;
    padding: 0 0 20px 20px;
    margin: 5px 0 5px 16px;
    h4 {
      font-weight: 600;
      text-transform: none;
      margin-bottom: 3px;
    }

    p {
      margin-bottom: 6px;
    }
  }
}

.event-info {
  padding: 10px;
  font-size: $font-size-small;
  margin-bottom: 20px;
  *[class^='col'] {
    border-color: rgba(0,0,0,0.1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    &:last-child {
      border-bottom: 0;
    }
  }
  @include mq(tablet) {
    *[class^='col'] {
      border-bottom: 0;
      border-left-width: 1px;
      border-left-style: solid;
      &:first-child {
        border-left: 0;
      }
    }
  }
}

.event-content {
  img {
    max-width: 100%;
    display: block;
    margin: 20px auto;
    @include box-shadow($shadow-medium);
  }
  text-align: justify;
  margin: 20px 0 40px;
}

.ficha-evento {
  position: relative;
  background: #fff;
  margin: 20px 0;
  border-radius: $border-radius-medium;
  @include box-shadow($shadow-medium);
  overflow: hidden;
  .img {
    float: left;
    width: 25%;
    height: 100px;
    padding: 15px 15px 0;
    img {
      max-width: 100%;
      max-height: 100%;
      border: 4px #fff solid;
      @include box-shadow($shadow-medium);
    }
  }
  .header {
    min-height: 120px;
    padding: 15px;
    background: #f04124;
    h3 {
      color: #fff;
      margin: 0;
    }
    h4 {
      color: $text-color;
      margin: 0;
    }
    .labels {
      width: 20%;
      float: right;
      margin: 20px;
    }
  }

  .content {
    padding: 15px;
    text-align: justify;
    font-size: 85%;
  }
}

.contenido-evento {
  h3 {
    margin-bottom: 10px;
  }
}

.fichas-eventos {
  .well {
    @include transition(all 0.2s ease-in-out);
    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    }
    .box-body {
      padding: 30px;
    }
  }
  .muted {
    color: $brand-primary;
  }
  .box-content {
    border-right: 1px solid rgba(black, 0.05);;
  }
  .btn {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: #fff;
    font-size: 14px;

  }
  .ficha-evento, .blog-post {
    .icon {
      width: 15px;
      font-size: 14px;
    }
  }
  p {
    font-size: 14px;
  }
  p.muted {
    color: grey;
  }

  .activity-labels {
    position: absolute;
    top: 0;
    right: 30px;
    margin: 0;
    span {
      border-radius: 0 0 4px 4px;
      font-size: 13px;
      font-weight: 200;
    }
  }
  a {
    display: block;
    color: inherit;
  }
}

/* ITEM SIDEBAR */

.item-sidebar {
  @include box-shadow($shadow-small);
  overflow: hidden;
  background: transparentize(#fff, 0.12);
  .img {
    padding: 10px;
    text-align: center;
    img {
      max-width: 100%;
      height: 200px;
      @include mq(tablet) {
        width: 100%;
        height: auto;
      }
    }
  }
  .block {
    padding: 0 10px 10px;
    //border-bottom: 1px $gray-lighter solid;
    &:last-child {
      //border: 0;
    }
    .title-pill {

    }
  }
  p {
    font-size: 85%;
    line-height: 1.2;
  }

}
.event-content{
  margin-bottom: 40px;
}
.event-requirements{
  @include box-shadow($shadow-small);
  padding: 10px;
  margin-bottom: 40px;
}