/*
 * Component: Callout
 * ------------------
 */

// Base styles (regardless of theme)
.callout {
  border-radius: 3px;
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      color: #eee;
    }
  }
  h4 {
    color: #fff;
    margin-top: 0;
    font-weight: 600;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code,
  .highlight {
    background-color: #fff;
  }

  // Themes for different contexts
  &.callout-danger {
    background-color: $brand-danger;
    border-color: darken($brand-danger, 10%);
  }
  &.callout-warning {
    background-color: $brand-warning;
    border-color: darken($brand-warning, 10%);
  }
  &.callout-info {
    background-color: $brand-info;
    border-color: darken($brand-info, 10%);
  }
  &.callout-success {
    background-color: $brand-success;
    border-color: darken($brand-success, 10%);
  }
}
