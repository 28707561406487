/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/

.owl-theme .owl-controls{
	margin-top: 10px;
	text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
	// color: #FFF;
	font-family: 'arial';
	font-weight: 600;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 0 0 0 5px;
	padding: 5px 10px;
	font-size: 20px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: $gray-light;
	// filter: Alpha(Opacity=50);/*IE7 fix*/
	// opacity: 0.5;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	// filter: Alpha(Opacity=100);/*IE7 fix*/
	// opacity: 1;
	text-decoration: none;
	background: $gray;
	color: #fff;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 12px;
	height: 12px;
	margin: 5px 7px 0 0;
	// filter: Alpha(Opacity=50);/*IE7 fix*/
	// opacity: 0.5;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: $gray-light;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	// filter: Alpha(Opacity=100);/*IE7 fix*/
	// opacity: 1;
	background: $gray;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 150px;
	background: url(img/AjaxLoader.gif) no-repeat center center
}

.owl-buttons {
	text-align: right;
	margin: -30px 20px 0 0;
}

.owl-pagination {
	text-align: left;
	margin-top: 25px;
}

#advantages-carousel .owl-item .item {
	margin: 0 5px;
	padding: 0;
	img {
		-webkit-border-radius: 3px;
	       -moz-border-radius: 3px;
	    		border-radius: 3px;
    }
}