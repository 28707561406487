header{
  .top-nav{
    width: 100%;
    position: fixed;
  	background: $brand-primary; // fall-back for <= IE8
  	background: rgba( $brand-primary, 0.7);
	body.test & { background-color: rgba( #dd4b39, 0.7); }
    z-index: 10;
    font-size: 16px;
    font-weight: 200;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
      a{
        color: #fff;
      }
    .user-sidebar-open, .admin-area-btn{
		font-family: $headings-font-family;
		letter-spacing: 1px;
		display: inline-block;
		cursor: pointer;
		color: #fff;
		.icon{
			font-size: 21px;
			line-height: 16px;
			float: right;
			margin-left: 10px;
		}
    }
    @include mq(desktop){
      position: fixed;
      top: 0;
      width: 100%;
    }
  }


  .top-nav-btn{
    display: inline-block;
    padding: 10px 6px;
    @include mq(tablet){
      padding: 10px 13px;
      border-left: 1px solid transparentize(#000,0.95);
      border-right: 1px solid transparentize(#000,0.95);
    }

    @include mq(desktop){
		padding: 10px 20px;
    }
    &:hover{
      background-color: transparentize(#000,0.9);
    }
    a,a:hover{
      text-decoration: none !important;
    }
  }

  .top-nav-left{
    opacity: 1;
    @include transition(opacity 0.3s ease-in-out);
    .top-nav-btn {
      float: left;
      border-right: 0;
      @include mq(tablet) {
        &:last-child {
          border-right: 1px solid transparentize(#000, 0.95);
        }
      }
    }
  }

  .top-nav-logo{
    position: absolute;
    padding-top: 4px;
    opacity: 0;
	visibility: hidden;
    @include transition(opacity 0.3s ease-in-out);
  }

  @include mq(desktop){
    .top-nav.variant{
      .top-nav-logo{
        opacity: 1;
		visibility: visible;
      }
      .top-nav-left{
        opacity: 0;
      }
    }
  }

  .header-content{
    z-index: 20;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    @include mq(mobile){
      background-size: cover;
    }
    @include mq(desktop){
      background-attachment: fixed;
      background-size: 100% auto;
    }
  }

  .logo-wrapper{
    padding: 60px 0 200px;
    width: 250px;
    margin: 0 auto;

    // @include mq(mobile){
    //   padding: 40px 0;
    //   //width: 50%;
    // }
    @include mq(tablet){
      //padding: 50px 0 60px;
      width: 220px;
      padding: 60px 0 220px;
      margin: 0;
    }
     @include mq(desktop){
       width: 310px;
       padding: 60px 0 160px;
     }
     @include mq(wide){
       //width: 30%;
       padding: 60px 0 220px;
     }
  }

  .sponsor-wrapper{
    padding: 10px 0 0 0;
    width: 100%;
    margin: 0 auto;
    .logo-img { 
      float: right;
      width: 18%;
      padding: 10px 0 0 0;
      margin-bottom: -50px;
      @include mq(mobile){
        margin-bottom: -60px;
      }
      @include mq(tablet){
        margin-bottom: 0px;
        width: 12%;
      }
    }
  }

  .logo-title{
  	text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 200;
    //@include text-shadow(1px 1px 0px rgba(255,255,255,0.5));
    color: #fff;
    span{
      background-color: #000;
      padding: 0 3px;
    }
  }
}


.main-nav{
  background: $green-100;
  body.test & { background-color: #dd4b39 ; }
  padding: 0 5px;
  //@include border-top-radius($border-radius-medium);
  @include clearfix();

  ul{
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li{
    display: block;
    float: left;
    width: 50%;
    text-align: left;
    @include mq(desktop){
      height: 30px;
    }
    a{
	    font-family: $headings-font-family;
      display: block;
      color: #fff;
      font-weight: 300;
      padding: 10px 6px;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      @include transition(background-color 0.2s ease-out);
      &:hover{
        background-color: transparentize(#fff,0.85);
        text-decoration: none;
      }
      @include mq(tablet){
          padding: 13px 6px;
          font-size: 17px;
      }
      @include mq(desktop){
          padding: 15px 8px;
          font-size: 21px; 
      }
      @include mq(wide){
          padding: 18px 12px;
          font-size: 23px;
      }
    }

    &.active a, &.active a:hover{
      background: #fff;
      color: $green-500;
    }

    @include mq(mobile){
      width: 25%;
    }

    @include mq(tablet){
      display: inline-block;
      float: none;
      width: auto;
      height: auto;
      text-align: center;
    }

  }

}


/* ======= Menu Móvil ======== */
.nav-mobile-btn {
  display: hidden;
  margin: 0 auto;
  text-align: center;
  width: 90px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  color: $brand-primary;
  border: 0 none;
  border-radius: 3px 3px 0 0;
  position: absolute;
  top: -40px;
  left: 50%;
  margin-left: -45px;
  overflow: hidden;
  font-size: 12px;
  border-bottom: 1px solid $brand-primary;
  outline: none;
}

@include mq($until: tablet){
  .main-nav {
    position: relative;
  }
  .nav-mobile-hidden {
    transform: translateY(100%);
    @include transition(all 0.3s ease-in-out);
  }
  .nav-mobile-visible {
    transform: translateY(0);
  }
  .nav-mobile-btn {
    display: block;
  }

  section.main-header, .section-title {
    position: relative;
    z-index: 10;
    margin-top: -1px;
  }
  section.main {
    position: relative;
    z-index: 10;
    background: #fff;
    margin-top: -1px;
  }
  .main-nav button .ion-android-arrow-dropdown-circle::before {
    content: "\f35f" !important;
  }
}
