.blog-post-box:nth-child(2) {
  @include mq(tablet) {
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
  }
}

//== Text properties

.muted { 
  color: $gray-light;

}

.text-white{
  color: #fff;
}

.text-arial{
  font-family: arial, sans-serif;
}

.text-thin {
	font-weight: 100 !important;
}
.text-light {
  font-weight: 200;
}

.text-regular{
  font-weight: 400; 
}

.text-uppercase {
	text-transform: uppercase;
}

.text-serif{
  font-family: $font-family-serif;
}
.text-sans-serif{
  font-family: $font-family-sans-serif;
}
.text-inverse{
  color: #fff;
  background: transparentize(#000,0.55);
  padding: 0px 4px;
  line-height: 120%;
}

/* TEXT - COLOR */
.color-green{ color: $green-100; }
.color-lime{ color: $lime-100; }

/* Margin & position */

.ab-50 {
	position: absolute;
	left: 50%;
	margin-left: -105px;
	top: 50%;
}

/* MARGINS */
.ml-10 { margin: 0 10px; }

.mt-5{
  @media (max-width: $screen-xs-max) {
    margin-top: 5px !important;
  }
}
.mb-5{
  @media (max-width: $screen-xs-max) {
    margin-bottom: 5px !important;
  }
}
.mt-0 { margin-top: 0 !important; }
.mb-0{ margin-bottom: 0 !important; }

.mt-10 { margin-top: 10px !important; }
.mb-10 { margin-bottom: 10px !important; }

.mt-20 { margin-top: 20px !important; }
.mb-20 { margin-bottom: 20px !important; }

.mt-30 { margin-top: 30px !important; }
.mb-30 { margin-bottom: 30px !important; }

.mt-40 { margin-top: 40px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-60 { margin-bottom: 60px !important; }

.pt-40 { padding-top: 40px !important; }

/* WRAPPERS */
.box-wrapper{
  padding: 20px;
}
.box-wrapper-lg{ padding: 30px; }
.box-wrapper-md{ padding: 20px; }
.box-wrapper-sm{ padding: 10px; }
.box-wrapper-xs{ padding: 5px; }
.border-radius-small{
  border-radius: $border-radius-small;
}
.border-radius-medium{
  border-radius: $border-radius-medium;
}

/* BACKGROUNDS */

.bg-primary{ background-color: $brand-primary; }
.bg-success{ background-color: $brand-success; }
.bg-info{ background-color: $brand-info; }
.bg-success{ background-warning: $brand-warning; }
.bg-success{ background-danger: $brand-danger; }

.bg-green-100{ background-color: $green-100 }
.bg-green-200{ background-color: $green-200 }
.bg-green-300{ background-color: $green-300 }
.bg-green-400{ background-color: $green-400 }
.bg-green-500{ background-color: $green-500 }

.bg-lime-100{ background-color: $lime-100 }
.bg-lime-200{ background-color: $lime-200 }
.bg-lime-300{ background-color: $lime-300 }
.bg-lime-400{ background-color: $lime-400 }
.bg-lime-500{ background-color: $lime-500 }

.bg-gray-darker { background-color: $gray-darker }
.bg-gray-dark { background-color: $gray-dark }
.bg-gray { background-color: $gray }
.bg-gray-light { background-color: $gray-light }
.bg-gray-lighter { background-color: $gray-lighter }

.backwards {
    direction: rtl;
    unicode-bidi: bidi-override;
}   
   
.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

/* VISIVILITY */

.hidden {
    display: none !important;
    visibility: hidden;
}
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px; 
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
.invisible {
    visibility: hidden;
}

/* CLEARS */

.clear-wide{
  @media (min-width: $screen-lg){
    clear: both;
  }
}
.clear-desktop{
  @media (min-width: $screen-md) and (max-width: $screen-md-max){
    clear: both;
  }
}
.clear-tablet{
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    clear: both;
  }
}
.clear-mobile{
  @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max){
    clear: both;
  }
}
.clear-mini{
  @media (max-width: $screen-xs-min - 1){
    clear: both;
  }
}

/* TITLE PILLS */

.title-pill{
  padding: 10px;
  margin-bottom: 10px;
  h1,h2,h3,h4,h5,h6{
    margin: 0;
  }
  a {
    text-decoration: none;
  }
}

/* LABELS */

.label-big{
  font-size: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.label-as-badge{
  border-radius: $badge-border-radius;
}

/* IMAGES */
.img-responsive-inline{
  max-width: 100%;
  height: auto;
}
.img-center img { margin: 0 auto; }

/* SCROLLING */
.scroll-disable{
  overflow-y: hidden;
}

/* ZOOM ICONS */
.zoom-2x { font-size: 2em; }
.zoom-3x { font-size: 3em; }
.zoom-4x { font-size: 4em; }
.zoom-5x { font-size: 5em; }


/* LOADINGS */
.loading-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize(#000,0.5);
  color: #fff;
  display: table;
  .loading-icon-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .loading-icon{
    animation: rotating .5s linear infinite;
    width: 90px;
    height: 90px;
    border: 6px #fff solid;
    border-right-color: transparent;
    border-radius: 50%;

  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* LISTS */
.fixed-width-list {
	margin-top: 30px;
	margin-bottom: 30px;
    list-style-type: none;
    margin-left: 30px;
    padding-left: 0;
	li {
		position: relative;
	}
	.fixed-width-icon {
		left: -30px;
		position: absolute;
		text-align: center;
		top: 0;
		width: 20px;
	}	
}