//== Sidebar overlay
.sidebar-overlay {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: $sidebar-overlay-bg;
    z-index: $zindex-sidebar-fixed - 1;
    //@include transition(visibility 0 linear .4s,opacity .4s cubic-bezier(.4,0,.2,1));
    // @include transform(translateZ(0));
	@include transition(all .5s cubic-bezier(0.55, 0, 0.1, 1));
}

.sidebar-overlay.active {
    opacity: 0.65;
    visibility: visible;
    // @include transition-delay(0);
}


//== Sidebar
.sidebar {
    position: fixed;
    width: 0;
    top: 0;
    bottom: 0;
    z-index: $zindex-sidebar-fixed;
    display: block;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border: none;
    padding: 15px 20px;
	@include mq(desktop){
		padding: 30px 40px;
	}
    @include transition(all 0.5s cubic-bezier(0.55, 0, 0.1, 1));

  // Prevent floats from breaking the sidebar
  // &:extend(.clearfix all);
    @extend .clearfix;

  // Scrollbar style for webkit browsers
    &::-webkit-scrollbar-track {
        border-radius: 2px;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F7F7F7;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #BFBFBF;
    }
    &.open {
		width: 90%;
		@include mq(mobile){
			min-width: $sidebar-desktop-width;
			width: $sidebar-desktop-width;
    	}
    }
    h3{
        font-weight: 100;
    }
    input[type='text'],
    input[type='password']{
        background-color: whitesmoke;
        border-color: #fff;
        color: darken($brand-primary, 30%)
    }
    table.table-normal{
        tr,td{
            font-weight: inherit;
            border: 0;
        }
    }
    .color-primary{ color: $green-100 }
    .color-secondary{ color: $lime-100 }
    
}

.sidebar-right{
    background-color: $green-500;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0, #2E5C3A),
		color-stop(1, #4EA362)
	);
	background-image: -o-linear-gradient(bottom, #2E5C3A 0%, #4EA362 100%);
	background-image: -moz-linear-gradient(bottom, #2E5C3A 0%, #4EA362 100%);
	background-image: -webkit-linear-gradient(bottom, #2E5C3A 0%, #4EA362 100%);
	background-image: -ms-linear-gradient(bottom, #2E5C3A 0%, #4EA362 100%);
	background-image: linear-gradient(to bottom, #2E5C3A 0%, #4EA362 100%);
		right: 0;
    @include translate3d($sidebar-desktop-width, 0, 0);
    @include box-shadow(0px 2px 15px rgba(0,0,0,0.35));
    &.open {
        @include translate3d(0, 0, 0);
    }
}

.sidebar-left{
    left: 0;
    background-color: $green-500;
    @include translate3d(0-$sidebar-desktop-width, 0, 0);
    @include box-shadow(0px 2px 15px rgba(0,0,0,0.35));
    &.open {
        @include translate3d(0, 0, 0);
    }
}

//== Sidebar content: 
.user-sidebar-close, .admin-sidebar-close{
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    .icon{
        font-size: 21px;
        line-height: 16px;
        float: left;
        margin-right: 10px;
  }
}

#usersidebar, #adminsidebar{
    font-weight: 100;
    color: #fff;
}

/* Inside Styles */

 .sidebar-menu{
    a{
        display: block;
        text-decoration: none;
        color: #fff;
        @include clearfix();
        // padding: 8px;
        margin-bottom: 6px;
        @include transition(all 0.2s ease-in-out);
        &:hover{
            color: $lime-100;
        }
    }
    ul{
        margin: 0;
        padding: 20px 0 40px;
    }
    li{
        list-style: none;
        &.active a, &.active a:hover{
            // border: 1px $lime-300 solid;
            color: $lime-100;
        }
        &.divider{
            border-top: 1px $lime-300 dotted;
            height: 5px;
        }
    }
    .icon{
        display: block;
        float: left;
        margin-right: 15px;
		// padding: 5px 0;
        // font-size: 1.8em;
		line-height: 1.6;
		font-size: 28px;
		width: 25px;
    }
    .text{
        padding: 5px 0;
        display: block;
        float: left;
        font-size: 20px;
        font-weight: 100;
        margin-right: 13px;
        .count{
            padding: 0 8px;
            margin-left: 5px;
            border-radius: $border-radius-small;
            background-color: $lime-100;
            color: $green-500;
            font-size: 14px;
            font-weight: 400;
        }

    }
 }

 .lang-selector{
   font-size: 80%;
   h6{
     margin: 30px 0 0 0;
   }
 }