// Area privada
.private-area{
	.main-nav{
		background-color: transparentize($green-100,0.25) !important;
	}

	.logo-wrapper{
		@include mq(desktop){
			padding-bottom: 80px !important;
		}
		@include mq(wide){
			padding-bottom: 90px !important;
		}
	}
}

// Profile manager
.user-profile-sheet{
  	background-color: $gray-lighter;
  	margin: 30px 0;
  	border-radius: $border-radius-medium;
	.user-profile-img{
	  border-radius: $border-radius-medium;
	}
  .user-profile-content{
	padding-right: 40px;
    h3{
      margin-bottom: 5px;
    }
  }

  .user-profile-content-details{
    line-height: 100%;
    border-radius: $border-radius-small;
    margin-bottom: 12px;
    padding: 10px;
  }
}



// Selector de conversaciones
.conversations-wrapper{
	position: relative;
	margin-bottom: 30px;
  	margin-top: 20px;
}

.conversations-slider{
	.owl-pagination{
		text-align: center;
		margin-top: 15px;
	}
	.owl-item {
		padding: 30px 0;
	}
	.conversation-item{
		margin: 0px 10px;
		box-shadow: 0 5px 10px rgba(black, 0.15);
		border-radius: $border-radius-small;
	}
	a{
		text-align: center;
		// border: 1px $gray-lighter solid;
		border-radius: $border-radius-small;
		padding: 10px;
		display: block;
		text-decoration: none;
		color: $gray;
		&:hover{
			background: $gray-lighter;
		}
	}
	.avatar{
		img{
			border-radius: 50%;

		}
	}
	.info{
		font-size: 0.8em;
		.name{
			font-family: $headings-font-family;
			font-size: 1.3em;
			font-weight: $headings-font-weight;
			padding: 15px 0;
			margin-bottom: 10px;
			border-bottom: 1px solid rgba(black, 0.05);
		}
		.num-messages {
			color: #cccccc;
		}
	}
	.active a{
		background: $green-500;
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			color-stop(0, #2E5C3A),
			color-stop(1, #4EA362)
		);
		background-image: -o-linear-gradient(bottom, #2E5C3A 0%, #4EA362 100%);
		background-image: -moz-linear-gradient(bottom, #2E5C3A 0%, #4EA362 100%);
		background-image: -webkit-linear-gradient(bottom, #2E5C3A 0%, #4EA362 100%);
		background-image: -ms-linear-gradient(bottom, #2E5C3A 0%, #4EA362 100%);
		background-image: linear-gradient(to bottom, #2E5C3A 0%, #4EA362 100%);
			right: 0;
		color: #fff;
		.num-messages {
			color: #fff;
			opacity: 0.5;
		}
	}
  	.blink a{
      animation: blink-animation 2s infinite;
      -webkit-animation: blink-animation 2s infinite;
	}
}
.conversations-prev, .conversations-next{
	position: absolute;
	background: $green-100;
	color: #fff;
	width: 32px;
	height: 38px;
	padding: 6px 0;
	text-align: center;
	border-radius: $border-radius-small;
	top: 40%;
	cursor: pointer;
	-webkit-user-select: none;  
	-moz-user-select: none;    
	-ms-user-select: none;      
	user-select: none;
	&:hover{
		background: $green-200;
	}
}
.conversations-prev{ left: -5px }
.conversations-next{ right: -5px }


// Vista de conversacion

.conversation-wrapper{
	background: #fff;
	position: relative;
	height: 500px;
	width: 100%;
	margin-bottom: 60px;
	box-shadow: 0 10px 20px rgba(black, 0.2);
	// @include transition(all 0.5s cubic-bezier(0.55, 0, 0.1, 1));
	&.fullscreen{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// @include transition(all 0.5s cubic-bezier(0.55, 0, 0.1, 1));
	}
}

.conversation-title{
	position:absolute;
	width: 100%;
	background-color: $green-500;
	color: #fff;
	padding: 10px;
	border-radius: 4px 4px 0 0;
	h4{
		// color: $lime-100;
		color: #fff;
		text-transform: none;
		font-weight: 100;
		margin: 0;
	}
	span {
		margin-right: 10px;
	}
}

.conversation-tools{
	position: absolute;
	right: 0;
	padding: 10px;
	button{
		color: #fff;
		background-color: transparent;
		border: 0;
		&:hover{
			border: 1px $green-100 solid;
		}
	}
	ul{
		margin:0; 
		padding:0;
	}
	li{
		list-style: none;
		display: inline;
	}
}

.messages-box{
	position: absolute;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	@include mq(desktop){
		overflow-y: auto;
	}
	padding: 20px;
	.message{
		@include clearfix();
		font-weight: 200;
		margin-bottom: 20px;
		padding: 10px;
		position: relative;
		border-radius: $border-radius-medium;
		width: 85%;
		border-width: 1px;
		border-style: solid;
		.avatar img{
			border-radius: 50%;
		}
	}
  	.hour{
	  font-size: 80%;
	  margin-top: -5px;
	  cursor: default;
	  float: right;
	}
	.message-user{
		margin-left: 15%;
		border-color: lighten($green-100,30%);
		background-color: lighten($green-100,40%);
		color: $green-400;
		.avatar{
			float: right;
			padding-left: 10px;
		}
	}
	.message-friend{
		margin-right: 15%;
		border-color: lighten($lime-100,30%);
		background-color: lighten($lime-100,40%);
		color: $lime-400;
		.avatar{
			float: left;
			padding-right: 10px;
		}
	  	.hour{

		}
	}
}

.message-write-box{
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: $green-500;
	padding: 10px;
	border-radius: 0 0 4px 4px;
  	input[type='text'],button, .btn{
		height: 100%;
		float: left;
		border-radius: $border-radius-small;
	}
	input[type='text']{
		background: whitesmoke;
		border: 1px #fff solid;
		color: $brand-primary;
		height: 40px;
		min-height: 40px;
		margin-right: 1%;
		margin-left: 1%;
		padding: 5px;
		width: 78%;
		max-width: 78%;
		min-width: 78%;
	}
	button, .btn {
		width: 10%;
		height: 40px;
	}

}

.conversation-wrapper.fullscreen{
	z-index: $zindex-fullscreen;
	.message-write-box, .conversation-title{
		border-radius: 0;
	}
}
.conversation-title{
	height: 40px;
}
.messages-box{
	top: 40px;
	bottom: 60px;
	width: 100%;
}
.message-write-box{
	height: 60px;
}

#modal-compose {
	textarea {
		font-family: $font-family-sans-serif;
		color: #89bf38;
	}
	button:not(.close) {
		margin-top: 10px;
		float: right;
	}
}

.publication-sheet{
  padding: 10px 0;
  border-bottom: 1px $gray-lighter solid;
  p{
	font-weight: 400;
  }
}


/* animation key frames */
@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100%{ opacity: 1; }
}
@-webkit-keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100%{ opacity: 1; }
}