// Allows positioning sidebar
html, body{
	position: relative;
	min-height: 100%;
	height: 100%;
}

// Font rendering optimization
body{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-smooth: always;
}

// Bootstrap container fluid width limitation
.container-fluid{
	max-width: $screen-lg;
//	position: relative;
}

// Tooltips
.tooltip{
	font-weight: 200;
}

// Karim
h1, .h1, h2, .h2, h3, .h3 {
	margin-bottom: 20px;
}

.well {
	padding: 0;
	border-width: 0;
	box-shadow: 0 10px 20px rgba(black, 0.2);
	margin-top: 20px;
	.box-body {
		padding: 20px;
	}
	.box-header, .box-footer {
		//padding: 30px;
	}
}