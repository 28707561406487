//----------
// Font-Face
//----------

// Ejemplo: @include FontFace('latolight', 'lato-light-webfont');

@mixin FontFace($ff-family, $ff-font-name, $ff-weight: normal, $ff-style: normal, $ff-directory: 'fonts') {
	@font-face {
		font-family: $ff-family;
		src: 	url('#{$ff-directory}/#{$ff-font-name}.eot'); 										// IE9 compat
		src: 	url('#{$ff-directory}/#{$ff-font-name}.eot?#iefix') format('embedded-opentype'), 	// IE8 and below
				// url('#{$ff-directory}/#{$ff-font-name}.woff2') format('woff2'), 					// Future standards
				url('#{$ff-directory}/#{$ff-font-name}.woff') format('woff'), 						// Standards
				url('#{$ff-directory}/#{$ff-font-name}.ttf') format('truetype'), 					// Safari, Android, iOS
				url('#{$ff-directory}/#{$ff-font-name}.svg##{$ff-family}') format('svg'); 			// Legacy iOS
		font-weight: $ff-weight;
		font-style:  $ff-style;
	}
}

//------------------------------------------
// Responsive font-size
// Mapa de variables font-size y line-height
// -----------------------------------------
// $p-font-sizes: (
//   null  : (15px, 1.3),
//   small : 16px,
//   medium: (17px, 1.4),
//   900px : 18px,
//   large : (19px, 1.45),
//   1440px: 20px
// );

// Ejemplo: p { @include font-size($p-font-sizes); }

@mixin font-size($fs-map, $fs-breakpoints: $mq-breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    font-size: $fs-font-size;
  }
}

// Clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

//Box solid color variantion creator
@mixin box-solid-variant($color, $text-color: #fff) {
  border: 1px solid $color;
  > .box-header {
    color: $text-color;
    background: $color;
    background-color: $color;
    a,
    .btn {
      color: $text-color;
    }
  }
}