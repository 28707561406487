.price-table {
  @include mq(tablet) {
    display: table;
  }
  .row-wrapper {
    @include mq(tablet) {
      display: table-row;
    }
  }
  .col-wrapper {
    display: block;
    width: 100%;
    margin: 0 auto;
    @include mq(tablet) {
      display: table-cell;
      width: 25%;
      margin: 0;
    }
    padding: 8px;
  }
  .price-col {
    text-align: center;
    background: transparentize(#fff, 0.12);
    padding: 20px;
    @include box-shadow($shadow-medium);
    border-radius: $border-radius-medium;
    @include mq(desktop) {
    }
    img {
      @include box-shadow($shadow-small);
    }
    h3 {
      color: $lime-500;
    }
    h2 {
      color: $gray-base;
    }
  }
}

.form-registration {
  @include mq(desktop) {
    .box {
      height: 700px;
    }
  }
}

/* AVANTATGES DE SOCI */
.advantages-list-item {
  display: block;
  border-radius: 5px 5px 0 0;
  margin-bottom: 20px;
  border: 1px solid $gray-lighter;
  @include transition(all .3s ease-in-out);
  p {
    padding: 10px;
    background: $gray-lighter;
    font-weight: 500;
    margin-bottom: 0;
  }
  &:hover {
    text-decoration: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}

.advantages-list-img {
  // border: 1px solid $gray-lighter;
  display: block;
  img {
    max-width: 100%;
    // max-height: 65%;
    height: 130px;
    width: auto;
    display: block;
    vertical-align: middle;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.advantages-title {
  margin: 30px 0;
  padding: 10px 0;
  border-bottom: 1px solid $gray-light;
  h2 {
    margin-bottom: 0;
  }
  .btn-xs, .btn-group-xs > .btn {
    margin-top: 7px;
    padding: 3px 10px;
    //background: $gray-light;
    //color: $gray;
  }
}

.btn-outline{
  border-color: rgba(255,255,255,0.5);
  background-color: transparent;
  color: rgba(255,255,255,0.8);
  &:hover{
    border-color: #fff;
    color: #fff;
  }
}

.input-group .btn {
  height: 35px;
}

.input-group .btn-sm {
  height: 34px;
}

.registration-options {
  $registration_items: 3;
  $registration_item_width: unquote(floor(100/$registration_items) + '%');

  margin-bottom: 20px;
  @include clearfix();

  .item {
    float: left;
    padding: 0 10px;
    text-align: center;
    @include mq(mobile, tablet) {
      width: 100%;
    }
    @include mq(tablet) {
      width: $registration_item_width;
    }
    a {
      display: block;
      padding: 10px;
      border-radius: $border-radius-medium;
      color: $green-300;
      text-decoration: none;
      @include transition(all .3s ease-in-out);
      &:hover {
        color: $text-color;
        background: $gray-lighter;
      }
    }
  }
}

.order-view {
  margin-bottom: 20px;
  @include box-shadow($shadow-small);
  border-radius: $border-radius-medium;
  border: 1px solid $gray-light;
  overflow: hidden;
  .order-header {
    background: $gray-lighter;
    padding: 5px 10px;
  }
  table{
   margin-bottom: 0;
  }
  th{
   font-size: $font-size-small;
  }
  .order-remove-line{
    color: $danger-normal;
  }
  .order-footer{
    border-top: 1px $gray-light solid;
    padding: 5px 10px;
  }
}