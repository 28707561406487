// Roboto
@include FontFace('roboto', 'roboto-thin-webfont', 100);
@include FontFace('roboto', 'roboto-light-webfont', 200);
@include FontFace('roboto', 'roboto-regular-webfont', 400);
@include FontFace('roboto', 'roboto-bold-webfont', 700);
@include FontFace('bebas', 'bebasneue_bold-webfont', 700);


// Roboto Slab
@include FontFace('robotoslab', 'robotoslab-thin-webfont', 100);
@include FontFace('robotoslab', 'robotoslab-bold-webfont', 700);