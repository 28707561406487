/* 
    Created on : 01-ene-2017, 10:47:41
    Author     : gos
*/

.thumbnail {
    max-width: 248px;
}

.box-album {
   .box-body {
    background-color: #ebebeb;
    padding-top: 20px;
    }
    h4 {
        font-size: 18px;
        max-height: 22px;
        color: #2e5c3a !important;
    }
    
    .thumbnail {
        ul {
            margin-bottom: 0;
        }
        li a, div a {
            font-size: 14px;
        }
        .footer {
            max-height: 25px;
        }
    }
    
    a.thumbnail {
        font-size: 12px;
        color: #2e5c3a;
        text-decoration: none;
    }
}